import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				О нас | Discover Suds & Shine AutoSpa
			</title>
			<meta name={"description"} content={"Где у каждого автомобиля есть своя история, а каждая мойка - это глава."} />
			<meta property={"og:title"} content={"О нас | Discover Suds & Shine AutoSpa"} />
			<meta property={"og:description"} content={"Где у каждого автомобиля есть своя история, а каждая мойка - это глава."} />
			<meta property={"og:image"} content={"https://bravzafrux.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://bravzafrux.com/img/images.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://bravzafrux.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://bravzafrux.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://bravzafrux.com/img/images.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://bravzafrux.com/img/images.png"} />
			<meta name={"msapplication-TileImage"} content={"https://bravzafrux.com/img/images.png"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--green"
			color="--dark"
			padding="80px 0"
			sm-padding="40px 0"
			background="--color-light"
		>
			<Text
				as="h5"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				Наше путешествие к блеску
			</Text>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
			О нас
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
			Компания Suds & Shine AutoSpa начала свою деятельность с идеи создать автомойку, которая не будет похожа на рутинную работу и станет удовольствием как для автомобиля, так и для его владельца. Наши корни уходят в убеждение, что каждый автомобиль заслуживает того, чтобы сиять, отражая гордость и радость своего владельца. За годы работы мы отточили свое мастерство, сочетая удобство современных автоматизированных моек с удовлетворением от работы в отсеках самообслуживания, сохраняя при этом приверженность принципам экологической устойчивости.
			</Text>
			<Box min-width="100px" min-height="100px">
				<Button
					type="link"
					font="--headline3"
					text-decoration-line="initial"
					color="--dark"
					border-radius="8px"
					border-width="1px"
					border-style="solid"
					border-color="--color-dark"
					background="rgba(0, 119, 204, 0)"
					href="/contacts"
				>
					Контакты
				</Button>
			</Box>
		</Section>
		<Section color="--dark" background="--color-light">
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Text as="h3" font="--headline2" margin="10px 0">
				Что отличает нас от других
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				В автосалоне Suds & Shine AutoSpa мы заботимся не только о блеске - мы заботимся об опыте. От момента, когда вы въезжаете в салон, до момента, когда вы покидаете его на сверкающем автомобиле, мы стремимся сделать каждое посещение незабываемым. Вот что делает нас особенными:
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				
				sm-margin="24px 0 0 0"
			>
				<Text as="h3" font="--headline2" margin="10px 0">
				Точность и аккуратность
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Наше оборудование отлажено до совершенства, что гарантирует отсутствие царапин и сверкающее покрытие каждый раз.

				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				
				sm-margin="24px 0 0 0"
			>
				<Text as="h3" font="--headline2" margin="10px 0">
				Экологически чистая практика
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Экологичность лежит в основе нашей работы. Мы используем оборотную воду и экологически чистые чистящие средства, чтобы защитить нашу планету.

				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				
				sm-margin="24px 0 0 0"
			>
				<Text as="h3" font="--headline2" margin="10px 0">
				Прикосновение роскоши
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				наши помещения спроектированы с учетом вашего комфорта. Отдохните в нашем лаундже или выпейте бесплатный кофе в ожидании.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				
				sm-margin="24px 0 0 0"
			>
				<Text as="h3" font="--headline2" margin="10px 0">
				Расширение возможностей выбора
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Выберите одну из наших современных автоматизированных моек или возьмите управление на себя в наших отсеках самообслуживания. Ваш автомобиль, ваш выбор.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7" background="--color-light">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://bravzafrux.com/img/2.jpg"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://bravzafrux.com/img/3.jpg"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="60%"
					lg-width="100%"
					md-text-align="left"
				>
					Автосалон Suds & Shine AutoSpa - придание блеска, создание историй
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});